.card-div {
    margin: 1rem;
    padding: 1.5rem;
    border-radius: 0.4rem;

    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    text-align: left;

    cursor: pointer;
    transition: box-shadow .3s;
}
.card-div:hover {
    box-shadow: 0 0 0.25rem white;
}

.card-div-enter {
    transform: translateX(-100%);
}
.card-div-enter-active {
    transform: translate(0%);
    transition: transform 1.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.tags-container {
    margin-left: 2rem;
    display: flex;
}

.card-tag {
    max-height: 1.8rem;
    margin-right: 0.5rem;
    margin-bottom: 0.3rem;
    padding: 0.2rem 0.5rem 0.2rem 0.42rem;
    border-radius: 1.5rem;

    color: #fff;
    text-shadow: 2px 2px rgba(189, 189, 189, 0.08);
    font-weight: 500;

    display: flex;
    flex-shrink: 0;
}
.card-answer-tag {
    padding: 0.4rem 0.9rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    text-align: left;

    color: #fff;
    text-shadow: 2px 2px rgba(189, 189, 189, 0.08);
    font-weight: 500;
}

.work-tag {
    margin-right: 0.5rem;
}
.work-tag-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.work-cause-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    height: 1.2rem;
}

.datetime-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 1rem;
}

.entry-date {
    text-align: left;
    font-size: 1.2rem;

    color: #fff;
    text-shadow: 2px 2px rgba(0,0,0,0.05);
}
.date-mobile {
    display: none;
}

@media screen and (max-width: 956px) {
    .card-div {
        overflow-x: auto;
    }
}

@media screen and (max-width: 515px) {
    .card-div {
        padding: 0.7rem 1rem;
        margin: 1rem 0.5rem;
    }

    .date-desktop {
        display: none;
    }
    .date-mobile {
        display: inline;
    }
    .tags-container {
        margin-left: 1rem;
    }

    .card-tag {
        font-size: 0.9rem;
    }
    .work-cause-icon {
        height: 1rem;
    }
    .card-answer-tag {
        padding: 0.2rem 0.6rem;
        margin-top: 0.1rem;
        font-size: 0.95rem;
    }
    
    .datetime-container {
        margin-bottom: 0;
    }
    .entry-date {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 320px) {
    .work-cause-icon {
        margin-left: 0.1rem;
        margin-right: 0.1rem;
    }
}