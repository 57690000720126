.barchart-container {
    width: 100%;
    max-height: 90%;
    padding: 0 0.5rem;
    margin-bottom: 4rem;
}

.grouped-bar-chart {
    background-color: #063a76c9;
    border-radius: 5%;
    padding-bottom: 0.1rem;
}

.grouped-rect-label {
    font-size: 1em;
    font-weight: 700;
    fill: white;
}

.grouped-rect-value {
    font-size: 1em;
    font-weight: 700;
    fill: white;
}

.horizontal-legend-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}

.chart-legend {
    margin: 0rem 1rem;
    display: flex;
    flex-direction: row;
    color: #063a76c9;
    font-weight: 600;
    align-items: center;
}

.color-box {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.5rem;
}

.barchart-legend-text {
    font-size: 13px;
    color: rgb(185, 183, 183);
}

@media screen and (min-width: 700px) {
    .barchart-container {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }
    .grouped-bar-chart {
        height: 75%;
        width: 75%;
    }
    
    .chart-legend {
        font-size: 1.5rem;
    }

    .color-box {
        height: 2rem;
        width: 2rem;
    }
}

@media screen and (min-width: 900px) {
    .grouped-bar-chart {
        height: 65%;
        width: 65%;
    }
}