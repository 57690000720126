.paymentsForm {
  margin: 1.1rem 0.1rem;
  overflow-y: auto;
  text-align: center;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 1.5rem;
  text-align: center;
  gap: 1rem;
}

.errorMessage {
  font-size: 1.3rem;
}

.errorButtonSection {
  width: 100%;
}

.errorButton {
  background-color: #4f86ec;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  transition: 0.2s;
  width: 90%;
  display: block;
  margin-bottom: 0.6rem;
  margin-left: auto;
  margin-right: auto;
}

.errorButton:hover {
  background-color: #497ddf;
  color: white;
  text-decoration: none;
}

.helpContainer {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.promoCodeError {
    font-size: 0.8rem;
    margin: auto;
    color: #838383;
    margin: 1rem auto 0;
}

@media screen and (min-width: 500px) {
    .promoCodeError {
        font-size: 0.87rem;
    }
}

@media screen and (min-width: 1400px) and (min-height: 800px){
    .promoCodeError {
        font-size: 0.95rem;
    }
}