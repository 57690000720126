.auth-container {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.7rem;
  font-weight: bold;
}

.firebase-auth-form-container {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem 0;
  width: 100%;
}

.email-password-container {
  display: flex;
  flex-direction: column;
}

.auth-error {
  color: red;
  font-size: 1rem;
  margin-bottom: 0;
  text-align: center;
}

.forgot-password {
  align-self: flex-start;
  margin: 0.5rem 0.3rem 0.3rem;
  color: black;
  text-decoration: underline;
}

.signin-signup-button {
  margin: 1rem 0;
  background-color: var(--signGreen);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  transition: 0.2s;
}

.signin-signup-button:hover {
  background-color: var(--signGreenHover);
  text-decoration: none;
  color: white;
}

.switch-button-container {
  margin: 0.2rem;
  text-align: center;
}

.switch-button {
  margin: 0;
  text-align: right;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #497ddf;
  text-decoration: underline;
}

.switch-button:hover {
  color: #b049df;
}

.password-helper-text {
  color: blue;
  font-size: 0.7rem;
  margin: 0 0.2rem;
  margin-bottom: 0;
  text-align: center;
}

/* Tablet - Increase signin/signout card by 10% */
@media screen and (min-width: 500px) {
  .auth-container {
    font-size: 1.1rem;
    padding: 1.1rem;
  }

  .title {
    font-size: 1.87rem;
    margin-bottom: 0.55rem;
  }

  .firebase-auth-form-container {
    margin: 0.55rem 1.1rem 0;
  }

  .forgot-password {
    margin: 0.55rem 0.33rem 0.33rem;
  }

  .signin-signup-button {
    margin: 1.1rem 0;
    padding: 0.55rem 1.1rem;
    border-radius: 2.2rem;
    font-size: 1.65rem;
  }

  .switch-button-container {
    margin: 0.22rem;
  }

  .password-helper-text {
    font-size: 0.77rem;
    margin: 0 0.22rem;
  }
}

/* XL Monitor -  Increase signin/signout card by 50% */
@media screen and (min-width: 1400px) and (min-height: 800px) {
  .auth-container {
    font-size: 1.5rem;
    padding: 1.5rem;
  }

  .title {
    font-size: 2.55rem;
    margin-bottom: 0.75rem;
  }

  .firebase-auth-form-container {
    margin: 0.75rem 1.5rem 0;
  }

  .forgot-password {
    margin: 0.75rem 0.45rem 0.45rem;
  }

  .signin-signup-button {
    margin: 1.5rem 0;
    padding: 0.75rem 1.5rem;
    border-radius: 3rem;
    font-size: 2.25rem;
  }

  .switch-button-container {
    margin: 0.3rem;
  }

  .password-helper-text {
    font-size: 1.05rem;
    margin: 0 0.3rem;
  }
}
