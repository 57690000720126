.radio-tile {
    border-style: solid;
    border-width: 0.2rem;
    border-radius: 15px;
    border-color: rgb(197, 235, 212);

    outline: solid 0 rgb(197, 235, 212);
    transition: outline 0.2s cubic-bezier(0.33, 1, 0.68, 1);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;

    color: rgba(255, 255, 255, 0.8);
}

@media (hover: hover) {
    .radio-tile:hover {
        outline-width: 0.1rem;
        color: white;
    }
}


.default-radio-size {
    max-width: 22rem;
    height: 3.5rem;
    margin: 0.5rem;
    padding: 1.563rem;   
    font-size: 1.4rem;
}

.small-radio-size {
    max-width: 21rem;
    height: 2.5rem;
    margin: 0.625rem;
    padding: 1.3rem;   
    font-size: 1.25rem;
}

@media screen and (max-width: 490px) {
    .radio-tile {
        border-width: 0.15rem;
    }

    .default-radio-size {
        font-size: 1.1rem;
        height: 3rem;
        max-width: 16rem;
    }
    
    .small-radio-size {
        font-size: 1.0rem;
        height: 2.5rem;
    }
}

/* HIDE RADIO */
[type=radio] { 
    visibility: collapse;
    opacity: 0;
    width: 0;
    height: 0;
}