.causeModal {
    width: 100%;
    z-index: 200; /*coz the main app header has z-index 100 right now that i want to hide*/
    margin-top: -1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    backdrop-filter: saturate(120%) blur(26px);
}

.causeContainer {
    background-color: #4240404f;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 1.5rem;
    overflow-x: hidden;
}

.causeModalHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
}

.causeStoriesProgressContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    overflow-x: hidden;
}

.causeStoryBar {
    background-color: rgba(255, 255, 255, 0.647);
    height: 4px;
    min-width: 4px;
    border-radius: 5px;
    margin: 0 2px;
    flex-grow: 1;
}

.xCloseModal {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.causeHeadingContainer {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

.causeHeading {
    text-align: left;
    color: white;
}