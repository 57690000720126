.App {
    text-align: center;
    min-height: 100svh;
}

.app-center-container {
    display: flex;
    height: calc(100vh - 24px);
    align-items: center;
}

/* Standard syntax */
/* textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
} */

/* WebKit browsers (Safari, Chrome, etc.) */
textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
}

/* Firefox */
/* textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
} */

/* Internet Explorer 10-11 */
/* textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
} */