.flowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headingPrimary {
    font-size: 1.7rem;
    color: white;
    margin: 0 1.5rem 1.5rem;
    max-width: 42rem;
}

.headingSecondary {
    color: rgba(221, 235, 236, .66);
    font-size: 1rem;
    font-style: italic;
    margin: 0 3rem 0.5rem;
}

.answerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.answerSpacing {
    margin: 0.8rem;
    text-decoration: none;
    width: fit-content;
    transition: none;
}

.answerSpacing:hover {
    text-decoration: none;
    color: white;
}

.subscribeButton {
    font-size: 1.23rem;
    border-width: 3.5px;
    color: white;
}

@media screen and (min-width: 500px) {
    .headingPrimary {
        font-size: 2rem;
        margin: 0 2rem 2rem;
    }

    .headingSecondary {
        font-size: 1.25rem;
    }

    .answerSpacing {
        margin: 1rem;
    }

    .subscribeButton {
        font-size: 1.55rem;
        border-width: 4px;
    }
}