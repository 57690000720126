.header {
    /* How this container is positioned so that it overlaps with the main content div with the colored background */
    position: absolute;
    width: 100%;
    z-index: 100;

    /* How I want this container's children to be positioned */
    display: flex;
    justify-content: space-between;

    padding: 10px;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-item {
    color: white;
    text-decoration: none;
}

.header-item-container {
    padding: 0 0.2rem;
    cursor: pointer;
}

.header-item:hover {
    font-weight: bold;
    color: white;
    text-decoration: none;
}

.header-btn {
    padding: 0 0.4rem 0 0.2rem;
    cursor: pointer;
    background-color: rgba(245, 245, 245, 0.85);
    color: rgb(41, 40, 40);
    border: none;
    border-radius: 0.25rem;
}