.nav-btn-mobile {
    padding: 0.1rem;
    min-width: 2.5rem;
    max-width: 7rem;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    border-color: rgba(245, 207, 240, 0.5);
    font-size: 1.2rem;
    color: rgb(245, 207, 240, 0.9);
    margin: 1rem 0.5rem 2rem;
    background: none;
}

.nav-btn-mobile:disabled {
    border-color: rgba(211, 211, 211, 0.7);
    color: rgba(221, 221, 221, 0.7);
    background-color: rgba(211, 211, 211, 0.2);
}

.prev-mobile {
    float: left;
}
.next-mobile {
    float: right;
    width: 4.0rem;
    color: white;
    border-color: rgba(255,255,255,0.6);
    border-width: 0.125rem;
}
.finish-mobile {
    width: 5.5rem;
    float: right;
}