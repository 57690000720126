.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.listItemMoreSpacing {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.9rem;
}

.text {
    margin: 0 0 0 0.5rem;
    text-align: left;
}

@media screen and (min-width: 355px) {
    .listItemMoreSpacing {
        margin-bottom: 1.25rem;
    }
}

@media screen and (min-width: 550px) {
    .listItem {
        margin-bottom: 0.7rem;
    }

    .listItemMoreSpacing {
        margin-bottom: 1.4rem;
    }

    .icon {
        width: 25px;
        height: 25px;
    }

    .text {
        margin-left: 0.7rem;
    }
}

@media screen and (min-width: 700px) {
    .listItem {
        margin-bottom: 1rem;
    }

    .listItemMoreSpacing {
        margin-bottom: 1.7rem;
    }

    .icon {
        width: 30px;
        height: 30px;
    }
}