.floating-label-input-container {
  position: relative;
  margin: 0.8rem 0 0.2rem;
}

.toggle-password-eye {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  margin: auto 0;
  top: 0;
  bottom: 0;
  right: 1rem;
  background-color: white;
}

/* 
Input background doesn't turn blue on autofill inputs after text entered
https://stackoverflow.com/a/70652140/11137574
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*
Styles copied from https://github.com/Azadmel/normili-landing-page
Originally adapted from: https://dev.to/adrianbdesigns/let-s-create-a-floating-label-input-with-html-and-css-only-4mo8
*/

.floating {
  background-color: var(--field__background);
  transition: background-color 0.2s ease;
  text-align: left;
  border: 0.2rem rgb(223, 223, 223) solid;
  border-radius: 0.5rem;
}

.floating:hover,
.floating:focus-within {
  background-color: var(--field__background--hover);
}

.floatingInput {
  margin: 0.75rem 0.5rem;
  font-size: 1.1rem;
  transition: border-color 0.2s ease;
  caret-color: var(--color__accent);
  width: calc(100% - 3.5rem);
  border: none;
}

.floating:hover .floatingInput {
  border-color: var(--input__border--hover);
}

.floatingInput::placeholder {
  color: rgba(0, 0, 0, 0);
}

.floatingInput:focus {
  outline: none;
}

.widthWebkitFill {
  width: -webkit-fill-available;
}

.floatingLabel {
  display: block;
  position: relative;
  max-height: 0;
  font-weight: 500;
  pointer-events: none;
  color: gray;
  margin-bottom: 0;
}

.floatingLabel::before {
  color: var(--label__color);
  content: attr(data-content);
  display: inline-block;
  filter: blur(0);
  backface-visibility: hidden;
  transform-origin: left top;
  transition: transform 0.2s ease;
  left: 1rem;
  padding: 0 0.2rem;
  position: relative;
  font-size: 1.15rem;
}

.floatingLabel::after {
  bottom: 1rem;
  content: "";
  height: 0.15rem;
  position: absolute;
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
  opacity: 0;
  left: 0;
  top: 100%;
  margin-top: -0.1rem;
  transform: scale3d(0, 1, 1);
  width: 100%;
  background-color: var(--color__accent);
}

.floatingInput:focus + .floatingLabel::after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.floatingInput:placeholder-shown + .floatingLabel::before {
  transform: translate3d(0, -2.5rem, 0) scale3d(1, 1, 1);
}

.floatingLabel::before,
.floatingInput:focus + .floatingLabel::before {
  transform: translate3d(0, -4.3rem, 0) scale3d(0.82, 0.82, 1);
  background-color: white;
}

.floatingInput:focus + .floatingLabel::before {
  color: var(--color__accent);
}

.hiddenVisually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* 
  Tablet - Increase signin/signout card by 10%.
  Increasing size by 10% to match the size increase for the signin/signout card.
*/
@media screen and (min-width: 500px) {
  .floating-label-input-container {
    margin: 0.88rem 0 0.22rem;
  }

  .toggle-password-eye {
    height: 1.65rem;
    width: 1.65rem;
    right: 1.1rem;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 33px white inset !important;
  }

  .floating {
    border: 0.22rem rgb(223, 223, 223) solid;
    border-radius: 0.55rem;
  }

  .floatingInput {
    margin: 0.825rem 0.55rem;
    font-size: 1.21rem;
    width: calc(100% - 3.85rem);
  }

  .floatingLabel::before {
    font-size: 1.265rem;
  }

  .floatingLabel::after {
    bottom: 1.1rem;
    height: 0.165rem;
    margin-top: -0.11rem;
  }

  .floatingInput:placeholder-shown + .floatingLabel::before {
    transform: translate3d(0, -2.75rem, 0) scale3d(1, 1, 1);
  }

  .floatingLabel::before,
  .floatingInput:focus + .floatingLabel::before {
    transform: translate3d(0, -4.73rem, 0) scale3d(0.902, 0.902, 1.1);
    background-color: white;
  }

  .hiddenVisually {
    clip: rect(1.1px 1.1px 1.1px 1.1px);
    clip: rect(1.1px, 1.1px, 1.1px, 1.1px);
    height: 1.1px;
    margin: -1.1px;
    width: 1.1px;
  }
}

/* XL Monitor -  Increase signin/signout card by 50% */
@media screen and (min-width: 1400px) and (min-height: 800px) {
  .floating-label-input-container {
    margin: 1.2rem 0 0.3rem;
  }

  .toggle-password-eye {
    height: 2.25rem;
    width: 2.25rem;
    right: 1.5rem;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 45px white inset !important;
  }

  .floating {
    border: 0.3rem rgb(223, 223, 223) solid;
    border-radius: 0.75rem;
  }

  .floatingInput {
    margin: 1.125rem 0.75rem;
    font-size: 1.65rem;
    width: calc(100% - 5.25rem);
  }

  .floatingLabel::before {
    font-size: 1.2rem;
    /*  */
  }

  .floatingLabel::after {
    bottom: 1.5rem;
    height: 0.225rem;
    margin-top: -0.15rem;
    transform: scale3d(0, 1.5, 1.5);
  }

  .floatingInput:focus + .floatingLabel::after {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  .floatingInput:placeholder-shown + .floatingLabel::before {
    transform: translate3d(0, -4.15rem, 0) scale3d(1.5, 1.5, 1.5);
  }

  .floatingLabel::before,
  .floatingInput:focus + .floatingLabel::before {
    transform: translate3d(0, -6.8rem, 0) scale3d(1.23, 1.23, 1.5);
    /*  */
  }

  .hiddenVisually {
    clip: rect(1.5px 1.5px 1.5px 1.5px);
    clip: rect(1.5px, 1.5px, 1.5px, 1.5px);
    height: 1.5px;
    margin: -1.5px;
    width: 1.5px;
  }
}
