:root {
  --skyBlue: #bde3e0;
  --mountainRed: #d76c58;
  --roadBeige: #EAE6B1;
  --signGreen: #34bf7c;
  --signGreenHover: #2db573;
  --signGreenDark: #1E9864;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
