.big-pic-counter {
    height: min(90svw, 90svh, 26rem);
    width: min(90svw, 90svh, 26rem);

    border: solid 9px rgba(245, 245, 245, 0.1);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.big-pic-counter-appear {
    transform: scale(0);
}

.big-pic-counter-appear-active {
    transform: scale(1);
    transition: transform 1s cubic-bezier(0.33, 1, 0.68, 1); /* ease out cubic*/
}

.big-pic-counter-appear-done {
    transform: scale(1);
}

.big-pic-counter-exit {
    transform: scale(1);
}

.big-pic-counter-exit-active {
    transform: scale(0);
    transition: transform 1s cubic-bezier(0.12, 0, 0.39, 0); /* ease in sine */
}

.big-pic-counter-exit-done {
    transform: scale(0);
}

.num-flows-heading {
    font-size: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 0;
    color: white;
}

.sub-heading {
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
}

.sub-text {
    font-size: 1.1rem;
    color: white;
    margin-bottom: 0;
}

.celebration-bg-color {
    background-color: #f0d198;
}

.shimmer {
    background: linear-gradient(to right, #c37f00, #dca339, #c58919);
    background-size: 200% auto;
    color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shimmer 5s infinite linear;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200%;
    }
    100% {
      background-position: 200%;
    }
  }

.celebration-heading {
    color: #c37f00;
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
}

.celebration-txt {
    color: #c37f00;
    font-size: 1.25rem;
    margin: 0;
} 

.celebration-trophy {
    font-size: 7rem;
}


.counter-button-div {
    padding: 1rem;
}

.counter-exit-button {
    padding: 0.5rem 1rem;
    border-radius: 50%;
    border: none;

    font-size: 1.25rem;
    font-weight: bold;
    color: white;
    background-color: #fff3;
}

/* For larger mobile phones */
@media screen and (max-width: 440px) {
    .num-flows-heading {
        font-size: 2rem;
    }
    .sub-heading {
        font-size: 1.25rem;
    }
    .sub-text {
        font-size: 1rem;
    }

    .celebration-heading{
        font-size: 1.75rem;
    }
    .celebration-txt {
        font-size: 1.1rem;
    }
    .celebration-trophy {
        font-size: 5rem;
    }

    .counter-exit-button {
        font-size: 1.1rem;
    }
}

/* For smaller mobile phones */
@media screen and (max-width: 375px) {
    .num-flows-heading {
        font-size: 1.8rem;
    }   
    .sub-heading {
        font-size: 1.1rem;
    }
    .sub-text {
        font-size: 0.8rem;
    }

    .celebration-heading{
        font-size: 1.5rem;
    }
    .celebration-txt {
        font-size: 1rem;
    }
    .celebration-trophy {
        font-size: 4rem;
    }

    .counter-exit-button {
        font-size: 1rem;
    }
}