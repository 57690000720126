.months-row-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;
}

.month-of-year {
    text-align: center;
    color: rgb(235, 235, 235);
    font-size: 0.75rem;
}

.lock-key {
    font-size: 1.25rem;
    cursor: pointer;
}

.month-label-cell {
    min-width: 3rem;
    width: calc(100vw/6);
}

.tiny-months-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-evenly;
}
.tiny-month {
    margin-bottom: 0.5rem;
}
.tiny-month-title {
    color: white;
}
.tiny-weekday-cell {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 1rem;
    min-height: 1rem;
    width: calc(100vw/23); /* 7*3 = 21 plus 2 for extra space*/
    height: min(2rem, calc(100vw/23));
}
.tiny-week-date {
    display: flex;
    align-items: center;
    justify-content: center;

    color: rgba(255, 255, 255, 0.8);
    font-size: 0.625rem;
    min-height: 0.75rem;
    min-width: 0.75rem;
    border-radius: 40%;
}

@media screen and (min-width: 700px) {
    .month-of-year {
        font-size: 0.9rem;
    }

    .lock-key {
        font-size: 1.5rem;
    }

    .tiny-month {
        margin-bottom: 1rem;
    }

    .tiny-week-date {
        font-size: 1rem;
        min-width: 1.5rem;
        min-height: 1.5rem;
        border-radius: 45%;
    }
    .tiny-month-title{
        font-size: 1.25rem;
    }
}