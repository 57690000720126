.big-picture-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 2rem;

    background-color: black;
}

.page-heading-container {
    /* display: flex; */
}

.big-picture-heading {
    color: white;
    margin-bottom: 0.5rem;
}

.timespan {
    color: white;
    margin-bottom: 1.5rem;
}