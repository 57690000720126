.profile-container {
    height: auto;
}

.profile-div {
    margin-top: 4rem;
    margin-bottom: 1rem;
    display: block;
    max-width: 30rem;
    width: 90%;
}

.profile-heading {
    margin-top: 1rem;
}

.profile-field {
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.profile-field-label {
    align-self: flex-start;
    margin: 0.5rem;
    font-size: 0.9rem;
}

.profile-text-input {
    background: rgba(245, 243, 243, 0.164);
    border-radius: 1rem;
    border: none;
    outline: none;
    color: rgba(255, 255, 255);
    font-size: 1.25rem;
    font-weight: 500;
    resize: none;
    padding: 0.75rem;
    text-align: left;
}

.profile-workdays-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.profile-workhours-div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.profile-workhours {
    padding: 0.5rem;
    font-size: 1.1rem;
}

.profile-workhours-label {
    margin: 0.5rem;
}

.profile-save-btn {
    position: relative;
    bottom: 0;
    margin: 2rem 0;
}

.profile-save-btn:active {
    box-shadow: 0 5px #666;
    transform: scale(0.99);
}

@media screen and (max-width: 360px) {
    .profile-workhours-div {
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .profile-workdays-div {
        justify-content: center;
    }

    .center-field-heading {
        align-self: center;
    }
}