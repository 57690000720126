.greeting-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: inset 0 0 20px white;
  transition: box-shadow 0.3s ease-in;
}

.greeting-container:hover {
  box-shadow: inset 0 0 5px white, 0 0 5px rgba(255, 255, 255, 0.8);
  transition: box-shadow 0.3s ease-out;
}

.greeting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vmin;
  height: 80vmin;
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: inset 0 0 20px white;
  transition: box-shadow 0.3s ease-in;
  padding: 1rem;
  overflow: hidden;
}

.greeting-exit {
  border-radius: 50%;
}

.greeting-exit-active {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  border-radius: 0%;
  box-shadow: inset 0 0 0px white;
  transition: width 1s cubic-bezier(0.22, 1, 0.36, 1),
    height 1s cubic-bezier(0.22, 1, 0.36, 1),
    border-radius 1s cubic-bezier(0.22, 1, 0.36, 1),
    box-shadow 1s cubic-bezier(0.22, 1, 0.36, 1);
}

@keyframes textFadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes textFadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-container-exit {
  opacity: 1;
}

.text-container-exit-active {
  opacity: 0;
  transform: scale(1.25);
  transition: opacity 0.8s linear, transform 0.8s linear;
}

.text-container-exit-done {
  opacity: 0;
}

.greeting-text {
  color: rgba(255, 255, 255, 0.7);
}

#main-greeting {
  font-size: 5.5vmin;
}

#sub-greeting {
  font-size: 4vmin;
}

/* Media query targets screens with a max-width OR a max-height of 472px */
@media screen and (max-width: 472px), screen and (max-height: 472px) {
  .greeting {
    width: 90vmin;
    height: 90vmin;
    max-width: 90vw;
    max-height: 90vh;
  }

  .greeting-exit {
    border-radius: 50%;
  }

  .greeting-exit-active {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0%;
    box-shadow: inset 0 0 0px white;
    transition: width 1s cubic-bezier(0.22, 1, 0.36, 1),
      height 1s cubic-bezier(0.22, 1, 0.36, 1),
      border-radius 1s cubic-bezier(0.22, 1, 0.36, 1),
      box-shadow 1s cubic-bezier(0.22, 1, 0.36, 1);
  }

  #main-greeting {
    font-size: 6vmin;
  }

  #sub-greeting {
    font-size: 4.5vmin;
  }
}
