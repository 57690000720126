.formContainer {
    width: 100%;
    min-height: 100svh;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.paymentsContainer {
    border-radius: 1rem;
    min-height: 32rem;
    height: max-content;
    max-width: 26rem;
    width: 90%;
    background-color: white;
    transition: 0.5s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3rem 0 1rem;
    position: relative;
}

@media screen and (min-width: 1400px) and (min-height: 800px) {
    .paymentsContainer {
        max-width: 32rem;
    }
}