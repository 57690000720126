.main-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100svh;
}

.main-content-appear {
    opacity: 0;
}

.main-content-appear-active {
    opacity: 1;
    transition: opacity 0.25s ease-in;
}