.big-pic-btn {
    display: block;
    width: 90vw;
    max-width: 35rem;
    padding: 0.875rem 0.815rem;
    margin: 2rem 1rem;

    text-align: center;
    white-space: pre-wrap;
    font-size: 1.1rem;
    font-weight: 800;

    color: white;
    background-color: rgba(255,255,255,0.85);
    border-radius: 2rem;
    border: none;
    outline: none;
    box-shadow: 0 0 0.20rem white;
    transition: background-color 0.3s ease;
}

.big-pic-btn:hover {
    background-color: white;
}

@media screen and (min-width: 700px) {
    .big-pic-btn {
        font-size: 1.5rem;
    }
}