.big-pic-transition-page {
    width: 100%;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    background-color: black;
}

.big-pic-transition-page-appear {
    transform: rotateY(-180deg);
}

.big-pic-transition-page-appear-active {
    transform: rotateY(0deg);
    transition: transform 0.8s cubic-bezier(0.83, 0, 0.17, 1)
        /* easeInOutQuint */
}

.error-container {
    margin: auto;
    opacity: 0;
}

.error-container-enter {
    opacity: 0;
}

.error-container-enter-active {
    opacity: 1;
    transition: opacity 1s ease-out;
}

.error-container-enter-done {
    opacity: 1;
}

.loading-text-container {
    margin: auto;
    opacity: 0;
}

.loading-text-container-enter {
    opacity: 0;
}

.loading-text-container-enter-active {
    opacity: 1;
    transition: opacity 1s ease-out;
}

.loading-text-container-enter-done {
    opacity: 1;
}

.loading-text-container-exit-active {
    opacity: 0;
    transition: opacity 0.05s linear;
}

.loading-text-container-exit-done {
    opacity: 0;
    display: none;
}

.info-text {
    color: white;
    font-family: "Lucida Console", "Courier New", monospace;
}

.big-picture-page-enter {
    opacity: 0;
}

.big-picture-page-enter-active {
    opacity: 1;
    transition: all 2s ease-in-out;
}