.weekview {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.week-nav-div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.week-heading {
    color: rgba(255, 255, 255, 0.8);
    width: 11rem;
    font-weight: 500;
    font-size:1.4rem;
}

.slide-prev-enter {
    transform: translateX(-100%);
    opacity: 0
}
.slide-prev-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: transform 500ms ease, opacity 500ms ease;
}

.slide-next-enter {
    transform: translateX(100%);
    opacity: 0
}
.slide-next-enter-active {
    transform: translateX(0%);
    opacity: 1;
    transition: transform 500ms ease, opacity 500ms ease;
}

.week-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0.5rem 0rem;
}

.weekdays-container {
    display: flex;
    flex-direction: row;
}

.weekday-label-cell {
    min-width: 2.75rem;
    width: calc(100vw/7);
}
.weekday-cell {
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 2.75rem;
    min-height: 2.75rem;
    width: calc(100vw/7);
    height: min(4rem, calc(100vw/7));
}
.week-day {
    text-align: center;
    color: rgba(235, 235, 235, 0.85);
    font-size: 0.75rem;
}
.week-date {
    display: flex;
    align-items: center;
    justify-content: center;

    color: rgba(255, 255, 255, 0.8);
    font-size: 1.1rem;
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 40%;
}

.week-entries-heading {
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
    margin-top: 2rem;
    padding: 0.5rem;
}

@media screen and (min-width: 700px){
    .week-day {
        font-size: 0.9rem;
    }
    .week-date {
        font-size: 1.4rem;
        min-width: 2.75rem;
        min-height: 2.75rem;
        border-radius: 45%;
    }
    .week-heading {
        font-size: 1.7rem;
        width: 15rem;
    }
    .week-entries-heading {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 810px) {
    .weekview {
        overflow-x: hidden;
    }

    .week-date {
        min-width: 3rem;
        min-height: 3rem;
    }
}