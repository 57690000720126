.finish-container {
    margin: 4rem auto;
    padding: 1rem;
}
.finish-btn {
    padding: 0.2rem;
    width: 7.5rem;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    border-color: rgb(245, 207, 240);
    font-size: 1.2rem;
    color: rgb(245, 207, 240, 1);
}

.finish-btn:active:enabled { 
    transform: scale(0.95); 
} 

.finish-btn:disabled {
    border-color: rgba(211, 211, 211, 0.7);
    color: rgba(221, 221, 221, 0.7);
    background-color: rgba(211, 211, 211, 0.2);
}

.finish-btn:hover:enabled {
    color: rgb(252, 237, 250);
    box-shadow: inset 0 0 5px rgb(240, 174, 231), 0 0 1px rgb(245, 207, 240);
}