/* TimezoneRadioButtons.module.css */
.radioBtnGroup {
    display: flex;
    flex-direction: row;
    color: rgba(255, 255, 255, 0.8);
    flex-wrap: wrap;
}

.radioGroupBtn {
    border-style: solid;
    padding: 0.2rem 0.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hiddenInput {
    display: none;
}