.form-container {
    display: inline-block;
}
.form-container-exit {
    opacity: 1;
}
.form-container-exit-active {
    opacity: 0;
    transition: opacity 0.25s ease-in;
}
.form-container-exit-done {
    opacity: 0;
}

.form-subcontainer {
    display: flex;
    align-items: center;
}

/* On mobile */
.nav-container{
    padding: 1rem;
    margin-top: 1rem;
}