label {
    scroll-snap-align: start;
}

.checkbox-tile {
    border-style: solid;
    border-width: 0.2rem;
    border-radius: 2rem;
    border-color: rgb(197, 235, 212);
    
    outline: solid 0 rgb(197, 235, 212);
    transition: outline 0.2s cubic-bezier(0.33, 1, 0.68, 1);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    color: rgba(255, 255, 255, 0.8);
}

@media (hover: hover) {
    .checkbox-tile:hover {
        outline-width: 0.1rem;
        color: white;
    }
}

.default-size {
    max-width: 22rem;
    height: 2.0rem;
    margin: 0.625rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    font-size: 1.2rem;
}

.detailed-checkbox-size {
    width: 15.5rem;
    margin: 0.625rem;
    padding: 1.3rem;
    font-size: 1.2rem;
}

.icon-size {
    height: 1.4rem;
}

.secondary-text {
    margin-top: 0.5rem;
    font-size: 0.7rem;
}

@media screen and (max-width: 490px) {
    .checkbox-tile {
        border-width: 0.14rem;
    }  
    
    .default-size {
        font-size: 1.0rem;
        height: 1.5rem;
        max-width: 18rem;
        padding: 1.0rem;
        margin: 0.5rem;
    }

    .detailed-checkbox-size {
        padding: 0.9rem;
        width: 14rem;
    }

    .secondary-text {
        margin-top: 0.4rem;
        color: rgba(255, 255, 255, 0.5);
    }
}

/* Hide checkbox */
[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
}

.checkbox-content {
    white-space: nowrap;
}

.checkmark {
    color: green;
    padding-right: 0.25rem;
}
.checkmark-detailed {
    color: green;
    margin-left: 0.4rem;
}