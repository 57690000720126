.radio-btn-group {
    display: flex;
    flex-direction: row;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 0.75rem;
}


.radio-group-btn {
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.8);

    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 700px) {
    .radio-group-btn {
        font-size: 1.3rem;
    }
}