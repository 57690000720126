.link {
    color: rgb(197, 235, 212, 0.8);
    text-decoration: underline;
    cursor: pointer;
    background: none!important;
    border: none;
    padding: 10px!important;
}
.link:hover{
    color: rgb(197, 235, 212);
}