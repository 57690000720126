.errorContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.5rem;
    text-align: center;
    position: relative;
}

.title {
    font-size: 2.2rem;
    margin: 1rem;
}

.errorMessage {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.button {
    margin: 1rem 1rem 2rem;
    background-color: #4f86ec;
    color: white;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.5rem;
    transition: 0.2s;
}

.button:hover {
    background-color: #497ddf;
    color: white;
    text-decoration: none;
}

.havingIssuesText {
    font-size: 0.8rem;
}

/* Desktop View */
@media screen and (min-width: 1100px) and (min-height: calc((100vw * 299 / 1139.82) + 295px)) {
    .title {
        font-size: 2.3rem;
        margin: 2rem;
    }

    .errorMessage {
        font-size: 1.4rem;
    }

    .havingIssuesText {
        font-size: 1rem;
    }
}

/* XL Monitor View */
@media screen and (min-width:1400px) {
    .errorContainer {
        justify-content: center;
    }

    .title {
        margin-bottom: 3rem;
    }

    .errorMessage {
        margin-bottom: 2rem;
    }

    .button {
        margin-bottom: 3rem;
    }

    .havingIssuesText {
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
    }
}