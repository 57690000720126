.poweredByStripeText {
    position: absolute;
    color: #9e9e9e;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    top: 0.5rem;
    right: 0.6rem;
}

/* Desktop size */
@media screen and (min-width: 1100px) and (min-height: calc((100vw * 299 / 1139.82) + 295px)) {
    .poweredByStripeText {
        right: 1.3rem;
    }
}
