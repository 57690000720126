.page {
  width: 100vw;
  background-color: var(--skyBlue);
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  overflow: auto;
  justify-content: center;
  padding: 4.5rem 0 1rem 0;
}

.header {
  margin: 0.5rem 0.5rem;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 1rem);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.brandContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}

.brandContainer:hover {
  text-decoration: none;
}

.logo {
  color: red;
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
}

.brandTitle {
  color: red;
  font-size: 1.5rem;
  margin: 0;
  font-weight: 400;
  text-decoration: none;
}

.background {
  position: fixed;
  left: -1px;
  bottom: 0;
  width: calc(100vw + 1rem);
  display: none;
  z-index: 0;
}

.contentContainer {
  height: 100%;
  display: flex;
}

.heading {
  display: none;
  text-align: left;
  margin-top: 4rem;
  margin-left: 2rem;
  white-space: nowrap;
}

.primaryHeadingContainer {
  margin-bottom: 1rem;
}

.headingText {
  font-weight: bold;
  font-size: 3.2rem;
}

.subheadingContainer {
  position: relative;
  width: fit-content;
  margin: 0.6rem 0;
}

.subheadingText {
  font-size: 2rem;
}

.underline {
  position: absolute;
  right: 0.1rem;
  bottom: -0.6rem;
}

.underlineSubheadingOne {
  width: 19.5rem;
}

.underlineSubheadingTwo {
  width: 6.5rem;
}

.formContainer {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authCard {
  border-radius: 1rem;
  min-height: 32rem;
  height: max-content;
  max-width: 22rem;
  width: 90%;
  background-color: white;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

/* ------------------------------------------------------------------- */
/* Media Queries - Responsive Design */

@media screen and (min-width: 400px) {
  .header {
    margin: 0.5rem 1rem;
    width: calc(100% - 2rem);
  }
}

/* Tablet - Increase signin/signout card by 10% */
@media screen and (min-width: 500px) {
  .authCard {
    min-height: 35.2rem;
    height: max-content;
    border-radius: 1.1rem;
    max-width: 24.2rem;
  }
}

/*
  Show the background image if the height of the browser window is tall enough to fit the image and the text.
*/
@media screen and (min-width: 1100px) and (min-height: calc((100vw * 299 / 1139.82) + 295px)) {
  /*
    Min Height Calculation:
    Aspect ratio of the image is 299 height / 1139.82 width
    295px is the height of the text plus top margin that always needs to be visible
  */
  .page {
    padding: 0;
    justify-content: unset;
    height: 100svh;
  }

  .background {
    display: unset;
  }

  .heading {
    display: unset;
  }

  .hideRoad {
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background: var(--skyBlue);
    width: calc((100vw - 1050px) / 2);
    /* 
      (100 viewportWidth - (HeadingTextWidth + authCardWidth_) / 2
      
      Calculated by subtracting the width of the text (705px) and the width of the flip card (388px), margins included, from the viewport width.

      This finds the remaining space on the page, ie. how much space is around the sides of the flip card.
      
      Take that number and divide by two to find the space between the flip card and the right side of the page. It's divided by two because we know the flip card is centered on the right side screen container div

      Same formula is used for calculating the width for other screen size media queries below
    */
  }

  .contentContainer {
    flex-direction: row;
  }

  .header {
    margin: 1rem;
    align-items: flex-start;
  }

  .authCard {
    height: 35.2rem;
    margin-top: 1rem;
  }
}

/* Increase Heading font size for larger screens (ie. larger than macbook) */
@media screen and (min-height: 755px) and (min-width: 1200px) {
  .heading {
    margin-top: 6rem;
    margin-left: 4rem;
  }

  .primaryHeadingContainer {
    margin-bottom: 1.5rem;
  }

  .hideRoad {
    width: calc((100vw - 1118px) / 2);
  }
}

/* XL Monitor -  Increase signin/signout card by 50% */
@media screen and (min-width: 1400px) and (min-height: 800px) {
  .hideRoad {
    width: calc((100vw - 1260px) / 2);
  }

  .headingText {
    font-size: 3.5rem;
  }

  .authCard {
    height: 48rem;
    border-radius: 1.5rem;
    max-width: 33rem;
  }
}

/* Show full road on screens with very large heights */
@media screen and (min-height: 930px) and (max-width: 1399px) {
  .hideRoad {
    display: none;
  }
}

/* Show full road on screens with very large heights */
@media screen and (min-height: 1275px) {
  .hideRoad {
    display: none;
  }
}