.answer-input-div-appear {
    opacity: 0;
}
.answer-input-div-appear-active {
    opacity: 1;
    transition: opacity 0.25s ease-in;
}

.answer-container {
    padding: 10px;
    max-width: 700px;
    margin-left: 3rem;
    margin-right: 3rem;
    scroll-snap-type: y mandatory;
}
@media screen and (max-width: 321px) {
    .answer-container {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.answer-prompt {
    font-style: italic;
    font-size: 1.25rem;
    color: rgba(221, 235, 236, 0.66);
    margin-bottom: 0.3rem;
}

.journal-input {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 2rem;
    border: none;
    outline: none;
    padding: 1.5rem;

    color: rgba(255, 255, 255);
    font-size: 1.2rem;
    resize: vertical;

    width: calc(100vw - 332px); /* Works responsively until we get to a mobile size. 
    The 332px is the total horizontal size of padding, margins & other element widths */
    max-width: inherit;
    height: 50vh;
}
.something-else-details {
    padding: 1rem;
    height: 4rem;
    width: 80%
}

@media screen and (max-width: 490px){
    .journal-input {
        width: 85vw;
        max-width: 23rem;
        max-height: 25rem;
    }
    .something-else-details {
        font-size: 1rem;
    }
}
