.rewards-container-outer {
    width: 90svw;
    max-width: 25rem;
    min-height: min(620px, 85svh);
    margin-top: 2rem;
    border-radius: 1.25rem;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;

    transform-origin: center;
    overflow: hidden;
}

.rewards-container-inner {
    width: 100%;
    height: 100%;
    border-radius: 1.25rem;
    flex: 1;

    background-color: rgba(255, 255, 255, 0.11);
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0.25rem;

    transform-origin: bottom left; /* for animation purpose */
}

.reward-heading-img-container {
    width: inherit;
}

.reward-name {
    margin-bottom: 2rem;
}

.reward-img {
    min-width: min(10rem, 70%);
    width: 70%;
    max-width: 11.5rem;
    height: auto;
}

.reward-img-medium {
    min-width: 100px;
    max-width: 150px;
    width: 46%;
    height: auto;
    padding: 0 0.25rem;
    opacity: 0; /*Is animated*/
}

.rewards-combined-description {
    margin: 3rem 0.5rem 2rem 0.5rem;
    opacity: 0; /* This is animated below */
}

.reward-description {
    margin: 2rem 0.75rem;
    opacity: 0; /* This is animated below */
}

.reward-button {
    padding: 0.5rem 4rem;
    border-radius: 2rem;
    color: white;
    border: none;
    box-shadow: 0 0.2rem #6cae89;
    
    background-color: #77c299;
    border-color: #6cae89;
}

.reward-button:hover {
    background-color: #66bb8c;
}

.rewards-flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    margin-bottom: 1rem;
}

.pop-appear {
    transform: scale(0.5);
    opacity: 0;
}

.pop-appear-active {
    transform: scale(1);
    opacity: 1;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.pop-enter {
    transform: scale(0.5);
    opacity: 0;
}

.pop-enter-active {
    transform: scale(1);
    opacity: 1;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.pop-enter-done {
    transform: scale(1);
    opacity: 1;
}

@keyframes fadeInTopToBottom {
    0% {
      opacity: 0;
      transform: translateY(-20px); /* Start slightly left */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End in the original position */
    }
}

.fade-appear {
    opacity: 0;
}

.fade-appear-active {
    opacity: 1;
    /* transition: opacity 0.5s ease-out; */
    animation: fadeInTopToBottom 1.5s ease forwards;
}

.fade-appear-done {
    opacity: 1;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    /* transition: opacity 0.5s ease-out; */
    animation: fadeInTopToBottom 1.5s ease forwards;
}

.fade-enter-done {
    opacity: 1;
}

/* Pivot style enter and exit animations */
.pivot-enter {
    transform: rotateZ(15deg);
    opacity: 0;
}

.pivot-enter-active {
    transform: rotateZ(0); /* Rotate in view */
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.pivot-exit {
    transform: rotateZ(0); /* Start facing forward */
    opacity: 1;
}

.pivot-exit-active {
    transform: rotateZ(-40deg); /* Rotate out of view */
    opacity: 0;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.pivot-exit-done {
    display: none;
}

/* Appear animation for outer container */
.container-outer-appear {
    opacity: 0;
}

.container-outer-appear-active {
    opacity: 1;
    transition: opacity 1s ease-out;
}

.container-outer-appear-done {
    opacity: 1;
}

.container-outer-enter {
    opacity: 0;
}

.container-outer-enter-active {
    opacity: 1;
    transition: opacity 1s ease-out;
}

.container-outer-enter-done {
    opacity: 1;
}

/* Exit Animation for outer container */
.container-outer-exit {
    transform: scale(1);
    opacity: 1;
}

.container-outer-exit-active {
    transform: scale(0);
    opacity: 0;
    transition: transform 1s cubic-bezier(.36,0,.48,-0.38), opacity 1s cubic-bezier(0.64, 0, 0.78, 0);
}

.container-outer-exit-done {
    display: none;
}