.phoneNumberInput {
    display: flex;
    align-items: center;
    border-radius: 1rem;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(255, 255, 255);
    background: rgba(245, 243, 243, 0.164);
    width: 100%;
    max-width: 16rem;
}

.phoneNumberInputPrefix {
    margin-right: 0.5rem;
    color: #ffffffa3;
}

.phoneNumberInputField {
    border: none;
    outline: none;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgba(255, 255, 255);
    background: transparent;
    width: 100%;
}