.nav-btn {
    background: none;
}

.triangle-container {
    border: none;
    fill: transparent;
    stroke: rgba(255, 255, 255, 0.5);
    stroke-width: 2;
}

.triangle-container-previous {
    border: none;
    fill: transparent;
    stroke: rgba(255, 255, 255, 0.25);
    stroke-width: 2;
}

.triangle-container:disabled {
    stroke: rgba(255, 255, 255, 0.15);
    fill: transparent;
}
.triangle-container:hover:enabled {
    fill: #72f198;
}
.triangle-container-previous:hover:enabled {
    fill: #72f198;
}

@keyframes nudge {
    from {stroke: white;}
    to {stroke: #72f198;}
}
.triangle-nudge {
    stroke-width: 4px;
    stroke: white;
    animation-name: nudge;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-iteration-count: 8;
    animation-direction: alternate;
    animation-timing-function: linear;
}