.my-checkins-page {
    width: 100%;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.my-checkins-page-appear {
    opacity: 0;
}

.my-checkins-page-appear-active {
    opacity: 1;
    transition: opacity 0.5s linear;
}