.checkins-list-container {
    min-width: 70%;
    padding: 0.5rem;
    margin: 1rem 0.5rem 0.5rem 1.5rem;
}

@media screen and (max-width: 515px){
    .checkins-list-container {
        max-width: 100%;
        margin: 0 0 0.5rem 0;
    }
}
