.onboarding-window {
    background-color: white;
    color: white;
    border-radius: 1rem;

    position: relative;
    margin: 3rem 0;
    padding: 1rem 1rem 2.5rem;
    width: 463px;
    min-height: 36rem;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.onboarding-greeting {
    font-size: 1.7rem;
    font-weight: bold;
}

.onboarding-subgreeting {
    font-size: 1.4rem;
    margin: 1rem;
}

.inputs-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.text-input {
    text-align: center;
    background: rgba(245, 243, 243, 0.164);
    border-radius: 1rem;
    border: none;
    outline: none;

    margin-top: 1rem;
    padding: 1.2rem;
    max-width: 80%;

    color: rgba(255, 255, 255);
    font-size: 1.7rem;
    font-weight: 500;
    resize: none
}

.skinny-input {
    padding: 1rem;
    font-size: 1.2rem;
    max-width: 90%;
    text-align: left;
}

input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.custom-placeholder::placeholder {
    font-size: 1rem;
}

.onboarding-btn {
    padding: 1rem;
    margin-top: 1rem;
    width: 66%;

    border-radius: 2rem;
    border: none;
    outline: none;

    font-size: 1.1rem;
    font-weight: 800;
    color: white;
    background-color: rgba(255, 255, 255, 0.85);
    transition: background-color 0.3s ease;
}

.onboarding-btn:hover {
    background-color: white;
    cursor: pointer;
}

.onboarding-btn:disabled {
    background-color: rgba(195, 195, 195, 0.85);
}

.onboarding-back-div {
    display: flex;
    align-self: baseline;
}

.time-dropdown {
    border-radius: 1rem;
    border: none;
    outline: none;
    padding: 0.5rem;

    font-size: 1.4rem;
    font-weight: 500;
    color: rgba(255, 255, 255);
    background: rgba(245, 243, 243, 0.164);
}

.time-dropdown::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.time-dropdown::-webkit-datetime-edit-minute-field {
    color: white;
}

.text-input,
.skinny-input,
.time-dropdown:focus {
    box-shadow: 0 0 0.1rem 0.05rem rgba(245, 245, 245, 0.5);
}

.waking-hours-text {
    font-weight: bold;
    margin: 0.5rem;
    max-width: 20rem;
}

.future-title-text {
    margin: 0 0 2.5rem;
    max-width: 24rem;
}

.guider-text {
    max-width: 22rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 500px) {
    .onboarding-window {
        width: 90%;
    }

    .onboarding-btn {
        bottom: 3rem;
        padding: .8rem;
        font-size: 1rem;
        width: 75%;
    }

    .text-input {
        font-size: 1.4rem;
    }

    .skinny-input {
        padding: 0.8rem;
        max-width: 80%;
        font-size: 1.2rem;
    }
}

@media (max-width: 390px) {
    .onboarding-btn {
        width: 85%;
    }

    .onboarding-subgreeting {
        font-size: 1.3rem;
    }
}

@media (max-width: 340px) {
    .onboarding-btn {
        width: 100%;
    }

    .onboarding-subgreeting {
        font-size: 1.25rem;
    }

    .waking-hours-text {
        max-width: 17rem;
    }
}

@media (max-width: 375px) and (min-height: 570px) and (max-height: 670px) {
    .time-dropdown {
        padding: 0.5rem;
        font-size: 1.2rem;
    }
}

@media (max-width: 375px) and (max-height: 570px) {
    .onboarding-btn {
        bottom: 2rem;
        padding: .8rem;
        font-size: 1rem;
    }

    .inputs-container {
        margin-top: 1.5rem;
    }

    .time-dropdown {
        padding: 0.5rem;
        font-size: 1.2rem;
    }
}