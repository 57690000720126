.checkin-details-page {
    width: 100%;
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 3rem;
    padding-bottom: 2rem;
}

.swipeable-container {
    width: 100%;
    margin-top: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.swipeable-container-2 {
    flex-direction: column;
    overflow-x: hidden;
    margin-bottom: 1rem;
}

.back-to-cal-btn {
    padding: 0.1rem 0.4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 0.4rem;
    border-color: rgba(245, 207, 240, 0.5);
    font-size: 1.2rem;
    color: rgb(245, 207, 240, 0.9);
    margin: 1rem 0.5rem 2rem;
    background: none;
}

.checkin-details-back-btn-div {
    margin: 2rem 2rem 0.5rem 2rem;
}

.entry-detail-div {
    margin: 2rem 2rem 0.5rem 2rem;
    padding: 1rem;
    border-radius: 0.4rem;
    background-color: whitesmoke;
    max-width: 49rem;
}

.entry-datetime {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.entry-summary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1.5rem;
}

.entry-summary-subdiv {
    margin-right: 3rem;
}

.question-section {
    margin-top: 2rem;
}

.question-heading {
    text-align: left;
    font-weight: 300;
    font-size: 1rem;
}

.answer-tags-container {
    display: flex;
    flex-wrap: wrap;
}

.answer-blurb-container {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border-radius: 0.8rem;
    background-color: #0f0f0f0f;
    text-align: left;
    font-size: 1.1rem;
    color: white;
}

@media screen and (max-width: 571px) {
    .entry-summary > div:nth-child(3) {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 515px) {
    .entry-detail-div {
        margin: 0rem 1rem 0.5rem 1rem;
    }

    .checkin-details-back-btn-div {
        margin: 0rem 1rem 0.5rem 1rem;
    }

    .question-heading {
        font-size: 0.9rem;
    }

    .answer-blurb-container {
        font-size: 1rem;
    }
}