.account-circle-container {
  position: relative;
}

.account-circle {
  height: 2rem;
  width: 2rem;
}

.account-settings-dropdown-container {
  position: absolute;
  top: 2.2rem;
  right: 0.2rem;
  display: flex;
  flex-direction: column;
  width: 10rem;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 0.7rem solid white;
  align-self: flex-end;
  margin-right: 0.2rem;
  margin-bottom: -1px;
}

.dropdown-options-container {
  background-color: white;
  border-radius: 0.4rem;
}

.dropdown-options {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.account-circle-dropdown-button {
  color: black;
  text-decoration: none;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 1.2rem;
  margin: 0.2rem 0.6rem;
  padding: 0;
}

.account-circle-dropdown-button:hover {
  font-weight: bold;
  color: rgba(255, 0, 0, 0.432);
  text-decoration: none;
}

.account-circle-dropdown-button-unsubscribed {
  color: #e27458;
  text-decoration: none;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 1.2rem;
  margin: 0.2rem 0.6rem;
  text-decoration: none;
  padding: 0;
}

.account-circle-dropdown-button-unsubscribed:hover {
  font-weight: bold;
}

.m-0 {
  margin: 0;
}

.dropdown-linebreak {
  width: 100%;
  margin: 0.3rem 0;
  border-top: 0.2rem solid #e9e9e9;
}

.button-transparent {
  background-color: transparent;
  padding: 0;
  border: none;
}

.unsubscribed-alert-icon {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 0.8rem;
}
