.checkin {
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkin-appear {
    opacity: 0;
}
.checkin-appear-active {
    opacity: 1;
    transition: opacity 0.25s ease-in;
}
.checkin-exit {
    opacity: 1;
}
.checkin-exit-active {
    opacity: 0;
    transition: opacity 0.25s ease-out;
}
.checkin-exit-done{
    opacity: 0;
}