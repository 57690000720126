@media screen and (min-width: 700px) {
    .pie-svg {
        width: 80%;
        height: 80%;
    }

    .click-hint {
        transform: scale(0.5); /* Shrinks the GIF to 50% of its original size */
        transform-origin: center; /* Keeps the GIF centered while scaling */
    }
}

.error {
    color: gray;
    font-family: "Lucida Console", "Courier New", monospace;
}

.click-hint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}