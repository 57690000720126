.linebreak-container {
  width: 100%;
  margin: 0.5rem 0;
  position: relative;
  height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.linebreak {
  margin: 0;
  z-index: 0;
  border-top: 0.2rem solid #e9e9e9;
}

.text {
  margin: auto;
  position: absolute;
  top: -0.3rem;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 0.5rem;
  background-color: white;
  width: fit-content;
  color: #a2a2a2;
}

/* Tablet - Increase signin/signout card by 10% */
@media screen and (min-width: 500px) {
  .linebreak-container {
    margin: 0.55rem 0;
    height: 1.1rem;
  }

  .linebreak {
    border-top: 0.22rem solid #e9e9e9;
  }

  .text {
    padding: 0 0.55rem;
  }
}

/* XL Monitor -  Increase signin/signout card by 50% */
@media screen and (min-width: 1400px) and (min-height: 800px) {
  .linebreak-container {
    margin: 0.75rem 0;
    height: 1.5rem;
  }

  .linebreak {
    border-top: 0.3rem solid #e9e9e9;
  }

  .text {
    top: -0.5rem;
    padding: 0 0.75rem;
  }
}
