.insights-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.insights-subcontainer{
    width: 95vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    /* background-color: rgba(255, 255, 255, 0.18); */
    box-shadow: 0 0px 0.90rem #f2d665;
    border-radius: 15px;

    animation: fadein 0.5s ease;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#chartDiv {
    /* width: 95vw; */
    width: 100%;
    max-height: 90%;
    padding: 0 0.5rem;
    padding-bottom: 1rem;
}

.insights-heading {
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
}
.insights-heading-span {
    padding: 0.2rem 0.4rem;
    border-radius: 0.4rem;
}

.bubble-insight-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 1rem;
}

.iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 100%; /* this is weird and form doesn't display properly without it-stackoverflow */
}

.insights-feedback {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

@media screen and (min-width: 700px) {
    .insights-subcontainer {
        width: 90vw;
        max-width: 47rem;
    }
    .insights-heading {
        font-size: 1.5rem;
    }
    .bubble-insight-div {
        width: 75%;
    }
}