.question {
    background: transparent;
}
.question-enter, .question-appear {
    opacity: 0;
}
.question-appear-active {
    transition: opacity 250ms ease-in;
}
.question-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

.question-text-div {
    padding: 3rem;
}

.question-text {
    color: rgba(255, 255, 255, 0.8);
    font-size: 2rem;
    max-width: 730px;
    margin: 0 auto; /* Keeps something with max width centered */
}

.question-smalltext {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.2rem;
    max-width: 730px;
    margin: 0 auto; /* Keeps something with max width centered */
}

@media screen and (max-width: 490px) {
    .question-text {
        font-size: 1.5rem;
        max-width: calc(100vw - 2rem);
    }
    .question-text-div {
        padding-left: 1.4rem;
        padding-right: 1.4rem;
    }
    .question {
        padding-top: 1rem;
    }
}