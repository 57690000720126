.google-signin-button {
  padding: 0.5rem;
  border: 0.2rem rgb(223, 223, 223) solid;
  border-radius: 2rem;
  margin: 1rem;
  transition: 0.2s;
  width: 100%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4f86ec;
}

.google-signin-button:hover {
  background-color: #497ddf;
}

.google-text {
  margin-left: 0.4rem;
  font-weight: bold;
  color: white;
}

.googleLogoContainer {
  background-color: white;
  padding: 0.2rem;
  border-radius: 50%;
  display: flex;
}

.googleLogo {
  height: 20px;
  width: 20px;
}

@media screen and (min-width: 300px) {
  .google-text {
    margin-left: 0.5rem;
  }
}

/* Tablet - Increase signin/signout card by 10% */
@media screen and (min-width: 500px) {
  .google-signin-button {
    padding: 0.55rem;
    border: 0.22rem rgb(223, 223, 223) solid;
    border-radius: 2.2rem;
    margin: 1.1rem;
  }

  .googleLogoContainer {
    padding: 0.22rem;
  }

  .google-text {
    margin-left: 0.55rem;
  }

  .googleLogo {
    height: 22px;
    width: 22px;
  }
}

/* XL Monitor -  Increase signin/signout card by 50% */
@media screen and (min-width: 1400px) and (min-height: 800px) {
  .google-signin-button {
    padding: 0.75rem;
    border: 0.3rem rgb(223, 223, 223) solid;
    border-radius: 3rem;
    margin: 1.5rem;
  }

  .googleLogoContainer {
    padding: 0.3rem;
  }

  .google-text {
    margin-left: 0.75rem;
  }

  .googleLogo {
    height: 30px;
    width: 30px;
  }
}
