.formContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1.4rem;
    text-align: center;
    gap: 3rem;
}

.title {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 0;
}

.primaryText {
    margin-bottom: 2rem;
}

.passwordGuidelinesText {
    color: blue;
    font-size: 0.7rem;
    margin-bottom: 0;
}

.errorText {
    color: red;
    font-size: 1rem;
    margin-bottom: 0;
}

.button {
    background-color: #4f86ec;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    cursor: pointer;
    font-size: 1.5rem;
    transition: 0.2s;
    width: 100%;
    display: block;
    margin-bottom: 0.6rem;
}

.button:hover {
    background-color: #497ddf;
    color: white;
    text-decoration: none;
}

.havingIssuesContainer {
    font-size: 0.8rem;
}

@media screen and (min-width: 500px) {
    .title {
        font-size: 2rem;
    }

    .passwordGuidelinesText {
        font-size: 0.8rem;
    }

    .errorText {
        font-size: 1.2rem;
    }

    .havingIssuesContainer {
        font-size: 1rem;
    }
}

/* XL Screen */
@media screen and (min-width: 1400px) {
    .formContainer {
        margin: 3rem;
        gap: 4rem;
    }

    .title {
        font-size: 2.3rem;
    }

    .primaryText {
        font-size: 1.5rem;
        margin-bottom: 2.5rem;
    }

    .passwordGuidelinesText {
        font-size: 1rem;
    }

    .button {
        font-size: 2rem;
    }
}