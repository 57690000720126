.mailtoLink {
  margin: 0;
  text-align: right;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #497ddf;
  text-decoration: underline;
}

.mailtoLink:hover {
  color: #b049df;
}
